import React, { useCallback, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { ListProps } from '../protocols';

import { Option } from '../styles';

export const Lista: React.FC<ListProps> = ({
  inputRef,
  setIsOpen,
  setFilter,
  hasNextPage,
  fetchNextPage,
  data,
  selected,
  setSelected,
  isFetching,
  changeSelected,
  queryName,
  queryClient,
}) => {
  /**
   * wrapperRef
   * Ref da lista de opções utilizado para saber quando o final da lista foi atingido
   */
  const wrapperRef = useRef(null);

  /**
   * useCloseOptions
   * Fecha lista de opções ao clicar fora
   */
  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          queryClient.removeQueries(queryName);
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);

  /**
   * onScroll
   * Verifica se o scroll chegou ao final da lista de opções
   */
  const onScroll = async () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      const zoomLevel = Math.round(window.devicePixelRatio * 100);
      const offset = Math.abs(100 - zoomLevel); // essa é a margem que mencionei
      if (scrollTop + clientHeight >= scrollHeight - offset) {
        if (hasNextPage) {
          await fetchNextPage();
        }
      }
    }
  };

  /**
   * handleClick
   * Seleciona item da lista
   */
  const handleClick = useCallback(
    (option: { label: string; value: number }) => {
      option.label = `${option.label}`;
      setSelected(option);
      changeSelected(option);
      setIsOpen(false);
    },
    [changeSelected, setIsOpen, setSelected],
  );

  /**
   * renderOptions
   * Renderiza lista de opções
   */
  const renderOptions = useCallback(() => {
    /**
     * Caso a variavel data não seja undefined
     */
    if (data !== undefined) {
      if (data.pages[0].message === 'url not found') {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      if (data.pages[0].data.length <= 0) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      if (
        data.pages[0].message === 'Registros encontrados' &&
        data.pages[0].data.length <= 0
      ) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }

      return data.pages.map((page) => {
        return page.data.map((item: any) => {
          return (
            <Option
              key={item.value}
              className={selected.value === item.value ? 'selected' : ''}
              onClick={() => {
                handleClick(item);
                if (inputRef) {
                  inputRef.current.placeholder = `${item.label}`;
                  inputRef.current.value = '';
                }
                queryClient.removeQueries(queryName);
                setFilter('');
              }}
              onKeyPress={() => {
                handleClick(item);
                if (inputRef) {
                  inputRef.current.placeholder = `${item.label}`;
                  inputRef.current.value = '';
                }
                queryClient.removeQueries(queryName);
                setFilter('');
              }}
            >
              {`${item.label}`}
            </Option>
          );
        });
      });
    }
    /**
     * Caso não existam dados de acordo com a busca efetuada
     * será listada a opção abaixo
     */
    return <Option className="not-found">Nenhum registro encontrado.</Option>;
  }, [
    data,
    handleClick,
    inputRef,
    queryClient,
    queryName,
    selected.value,
    setFilter,
  ]);

  return (
    <ul ref={wrapperRef} onScroll={onScroll}>
      {data
        ? renderOptions()
        : !isFetching && (
            <Option className="not-found">
              Digite ao menos 3 caracteres para pesquisar.
            </Option>
          )}
      {isFetching && (
        <Option className="not-found">
          <Spinner animation="border" size="sm" className="spinner" />
        </Option>
      )}
    </ul>
  );
};
